import React from "react";
import "./App.css";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/storeConfig/store";
import Toast from "./components/toast/Toast";
import Loader from "./components/Loader";
import AppRoutes from "./routes/AppRoutes";
import { Helmet } from "react-helmet";
import LogoContainer from "../src/assets/images/logo-container.svg";
import { useSelector } from "react-redux";

function App() {
  const { orderDetails } = useSelector((state) => state.orderDetailsReducer);
  const merchantName = orderDetails?.data?.subsidiary?.name;
  const merchantLogo = orderDetails?.data?.subsidiary?.customization[0].value;
  return (
    <Provider store={store}>
      <PersistGate loading={<Loader />} persistor={persistor}>
        <AppRoutes />
        <Loader />
        <Toast />
        <Helmet>
          <meta charSet="utf-8" />
          <title>{merchantName}</title>
          <meta name="description" content={merchantName} />
          <link rel="canonical" href="http://mysite.com/example" />
          <link
            rel="icon"
            type="image/png"
            href={merchantLogo}
            sizes="16x16"
            data-react-helmet="true"
          />
        </Helmet>
      </PersistGate>
    </Provider>
  );
}

export default App;
