import React, { useEffect } from "react";
import styles from "./Qrcode.module.scss";
import QRCode from "react-qr-code";
import { apiClient } from "../../hooks/apiClient";
import {
  openLoader,
  closeLoader
} from "../../redux/actions/loader/loaderActions";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import {
  ToastSuccessStyles,
  ToastErrorStyles
} from "../../constants/toastStyles";
import Button from "../button/Button";
import { useDispatch } from "react-redux";
import encryptForge from "../../utils/encryptForge";
import orderStatus from "../../api/orderStatus";
import SuccessScreen from "../SuccessScreen/SuccessScreen";

type QRLinkProps = {
  reference: string;
};
const QrCode = ({ reference }: QRLinkProps) => {
  const dispatch = useDispatch();

  const [success, setSuccess] = React.useState<boolean>(false);
  const [qrCode, setQrCode] = React.useState<any>(null);
  const [paymentResponse, setPaymentResponse] = React.useState<any>({
    code: "",
    message: ""
  });

  const [madePayment, setMadePayment] = React.useState<any>();

  const payload = {
    reference: reference,
    payment_option: "NQR"
  };

  const errorResponse = (error: any) => {
    dispatch(closeLoader());
    const message =
      error?.response?.data?.message || error?.response?.data?.Message;
    dispatch(closeLoader());
    dispatch(
      openToastAndSetContent({
        toastStyles: ToastErrorStyles,
        toastContent: message
      })
    );
  };

  const getQRLink = async () => {
    dispatch(openLoader());
    const encryptedData = encryptForge(JSON.stringify(payload));
    try {
      const data: any = await apiClient.post("/checkout/order/pay", {
        data: encryptedData
      });
      setQrCode(data?.data?.data?.payment_detail?.redirect_url);
      dispatch(closeLoader());
      // orderStatus();
    } catch (error: any) {
      dispatch(closeLoader());
      errorResponse(error);
    }
  };

  const statusOrder = async () => {
    dispatch(openLoader());
    const statusInterval = setInterval(async () => {
      const encryptedData = encryptForge(JSON.stringify(payload));
      try {
        const data: any = await orderStatus(encryptedData);

        if (data?.order_summary?.payment_response_code !== "01") {
          dispatch(closeLoader());
          setPaymentResponse({
            code: data?.order_summary?.payment_response_code,
            message: data?.order_summary?.payment_response_message
          });
          setSuccess(true);
          clearInterval(statusInterval);
        }

        const orderPayment = data?.order_payments?.slice(-1)?.pop();

        if (
          data?.order_summary?.payment_response_code === "01" &&
          orderPayment?.order_payment_response_code !== "01"
        ) {
          dispatch(closeLoader());
          setPaymentResponse({
            code: orderPayment?.order_payment_response_code,
            message: orderPayment?.order_payment_response_message
          });
          setSuccess(true);
          clearInterval(statusInterval);
        }
      } catch (error: any) {
        dispatch(closeLoader());
        errorResponse(error);
        clearInterval(statusInterval);
      }
    }, 2000);
  };

  const getMadePayment = async () => {
    dispatch(openLoader());
    try {
      const data: any = await apiClient.get(`checkout/order/${reference}/made-payment`);
      setMadePayment(data)
      dispatch(closeLoader());
      // orderStatus();
    } catch (error: any) {
      dispatch(closeLoader());
      errorResponse(error);
    }
  };

  useEffect(() => {
    getQRLink();
  }, []);
  return (
    <>
      {success ? (
        <SuccessScreen response={paymentResponse} />
      ) : (
        <div className={styles.container}>
          <div className={styles.scan}>
            Scan the QR code below with your mobile
            <br /> banking app to complete payment
          </div>
          {!!qrCode && (
            <>
              <div className={styles.qrCode}>
                <QRCode value={qrCode} size={150} />
              </div>
              <Button
                value="I’ve made payment"
                onClick={() => {
                  getMadePayment()
                  statusOrder()
                }}
                className={styles.buttonActive}
              />
            </>
          )}
        </div>
      )}
    </>
  );
};

export default QrCode;
