import React, { ReactNode, useState, useEffect } from "react";
import styles from "./Parentcontainer.module.scss";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import BackgroundGreen from "../../assets/images/green-background.svg";
import Lock from "../../assets/images/lock.svg";
import Payinvert from "../../assets/images/logo-container.svg";
import { ReactComponent as MenuIcon } from "../../assets/images/show-details.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/close.svg";
import axios from "axios";
import { useDispatch } from "react-redux";
import {
  openLoader,
  closeLoader
} from "../../redux/actions/loader/loaderActions";

export default function ParentContainer({ children }: { children: ReactNode }) {
  const [showDetails, setShowDetails] = useState(false);
  const useStyles = makeStyles({
    root: {
      height: "647px",
      width: "550px",
      maxWidth: "550px",
      margin: "0 auto",
      background: "#E5E5E5"
    },
    style: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      background: "#E5E5E5",
      position: "relative"
    }
  });
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <Grid spacing={2} classes={{ root: classes.style }}>
      <Grid
        item
        xs={12}
        md={4}
        classes={{
          root: classes.root
        }}
      >
        {children}
      </Grid>
      <div className={styles.backgroundGreen}>
        <img
          src={BackgroundGreen}
          alt="green-background"
          className={styles.backgroundGreenLogo}
        />
      </div>
      <div className={styles.secured}>
        <img src={Lock} alt="lock" className={styles.lock} />
        Secured by
        <img
          src={Payinvert}
          alt="logo-container"
          className={styles.logoContainer}
        />
      </div>
    </Grid>
  );
}
