import rsa from "node-forge";
import queryString from "query-string";

const body = queryString.parse(queryString.extract(window.location.search));

const BigInteger = rsa.jsbn.BigInteger;

let parser = new DOMParser();

const rsaPublicKey = sessionStorage.getItem("rsaPublicKey");

let encryptionKey = !!body?.encryptionKey
  ? body?.encryptionKey?.split(" ")?.join("+")
  : rsaPublicKey;

function encryptForge(data, rsa_pub_key) {
  let pk = encryptionKey || rsa_pub_key || rsaPublicKey;
  let rsaKeyValue = atob(pk);
  rsaKeyValue = rsaKeyValue.replace("4096!", "");
  const xmlDoc = parser.parseFromString(rsaKeyValue, "text/xml");
  const modulus = xmlDoc.getElementsByTagName("Modulus")[0].innerHTML;
  const exponent = xmlDoc.getElementsByTagName("Exponent")[0].innerHTML;

  const pubKey = rsa.pki.setRsaPublicKey(
    parseBigInteger(modulus),
    parseBigInteger(exponent)
  ); // e
  let encryptText = pubKey.encrypt(rsa.util.encodeUtf8(data));
  return btoa(encryptText);
}

function parseBigInteger(b64) {
  return new BigInteger(
    rsa.util.createBuffer(rsa.util.decode64(b64)).toHex(),
    16
  );
}

export default encryptForge;
