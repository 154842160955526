import React from "react";
import styles from "./Paymentdetails.module.scss";
import OutlineTextInput from "../../components/input/OutlineTextInput";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/button/Button";
import Radio from "@mui/material/Radio";
import { ReactComponent as CreditCard } from "../../assets/images/credit-card.svg";
import { ReactComponent as Bank } from "../../assets/images/bank.svg";
import { ReactComponent as USSD } from "../../assets/images/ussd.svg";
import { ReactComponent as TransferIcon } from "../../assets/images/transfer.svg";
import { ReactComponent as QRcodeIcon } from "../../assets/images/qr-code.svg";
import DebitCard from "../../components/DebitCard/DebitCard";
import Transfer from "../../components/Transfer/Transfer";
import Ussd from "../../components/Ussd/Ussd";
import QrCode from "../../components/QrCode/QrCode";
import Banks from "../../components/Banks/Banks";
import { apiClient } from "../../hooks/apiClient";
import {
  openLoader,
  closeLoader
} from "../../redux/actions/loader/loaderActions";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import {
  ToastSuccessStyles,
  ToastErrorStyles
} from "../../constants/toastStyles";
import ItemSummary from "../../components/ItemSummary/ItemSummary";

const PaymentDetails = () => {
  interface initTypes {
    CardNumber?: number;
    MonthAndYear?: string;
    Cvv?: string;
    Note?: string;
    DebitCard?: string;
    Bank?: string;
    Transfer?: string;
    Ussd?: string;
    QRCode?: string;
  }

  const initProps: initTypes = {
    CardNumber: 0,
    MonthAndYear: "",
    Cvv: "",
    DebitCard: "debit card",
    Bank: "bank",
    Transfer: "transfer",
    Ussd: "ussd",
    QRCode: "qrcode"
  };

  const dispatch = useDispatch();
  const handleFormBlur = () => {
    // dispatch(updateIndividualForm({ ...inputs }));
  };

  const [inputs, setInputs] = React.useState(initProps);

  const [selectedValue, setSelectedValue] = React.useState("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  const disabledFunction = () => {
    if (!inputs.CardNumber || !inputs.MonthAndYear || !inputs.Cvv) return true;
  };
  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;
    setInputs((prevState: initTypes) => {
      return {
        ...prevState,
        [inputName]: newValue
      };
    });
  };

  const { orderDetails } = useSelector((state) => state.orderDetailsReducer);
  const reference = orderDetails?.data?.order?.reference;
  const paymentReference = orderDetails?.data?.order?.payment_reference;

  return (
    <div className={styles.payment}>
      <div className={styles.details}>Payment Details</div>
      <div className={styles.method}>
        Select a payment method to complete your transaction.
      </div>
      {/* <ItemSummary /> */}
      <div className={styles.businessForm}>
        <div className={styles.formSelection}>
          <div className={styles.radioSelection}>
            <Radio
              checked={selectedValue === inputs.DebitCard}
              onChange={handleChange}
              value={inputs.DebitCard}
              name="DebitCard"
              inputProps={{ "aria-label": inputs.DebitCard }}
              size="small"
            />
            <CreditCard className={styles.selectionIcon} />
            <div className={styles.radioLabel}>Debit Card</div>
          </div>
          <div className={styles.radioSelection}>
            <Radio
              checked={selectedValue === inputs.Bank}
              onChange={handleChange}
              value={inputs.Bank}
              name="QRCode"
              inputProps={{ "aria-label": inputs.Bank }}
              size="small"
            />
            <Bank className={styles.selectionIcon} />
            <div className={styles.radioLabel}>Bank</div>
          </div>
          <div className={styles.radioSelection}>
            <Radio
              checked={selectedValue === inputs.Transfer}
              onChange={handleChange}
              value={inputs.Transfer}
              name="Transfer"
              size="small"
              inputProps={{ "aria-label": inputs.Transfer }}
            />
            <TransferIcon className={styles.selectionIcon} />
            <div className={styles.radioLabel}>Transfer</div>
          </div>
          <div className={styles.radioSelection}>
            <Radio
              checked={selectedValue === inputs.Ussd}
              onChange={handleChange}
              value={inputs.Ussd}
              name="Ussd"
              size="small"
              inputProps={{ "aria-label": inputs.Ussd }}
            />
            <USSD className={styles.selectionIcon} />
            <div className={styles.radioLabel}>USSD</div>
          </div>
          <div className={styles.radioSelection}>
            <Radio
              checked={selectedValue === inputs.QRCode}
              onChange={handleChange}
              value={inputs.QRCode}
              name="QRCode"
              size="small"
              inputProps={{ "aria-label": inputs.QRCode }}
            />
            <QRcodeIcon className={styles.selectionIcon} />
            <div className={styles.radioLabel}>QR Code</div>
          </div>
        </div>

        <div>
          {selectedValue === inputs.DebitCard && (
            <DebitCard reference={reference} />
          )}
        </div>
        <div>
          {selectedValue === inputs.Transfer && (
            <Transfer reference={reference} />
          )}
        </div>
        <div>
          {selectedValue === inputs.Ussd && <Ussd reference={reference} />}
        </div>
        <div>
          {selectedValue === inputs.QRCode && <QrCode reference={reference} />}
        </div>
        <div>
          {selectedValue === inputs.Bank && (
            <Banks reference={reference} paymentReference={paymentReference} />
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentDetails;
