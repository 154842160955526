import React, { useState, useEffect } from "react";
import PersonalInformation from "../PersonalInformation/PersonalInformation";
import PaymentDetails from "../PaymentDetails/PaymentDetails";
import Logo from "../../assets/images/logo.svg";
import TabArrow from "../../assets/images/tab-arrow.svg";
import styles from "./Checkouthome.module.scss";
import { useSelector } from "react-redux";
import axios from "axios";
import queryString from "query-string";

const CheckoutHome = () => {
  const [tab, setTab] = useState("personal");

  const [paymentLinkDetails, setPaymentLinkDetails] = useState<any>();
  const [subsidiaryDetails, setSubsidiaryDetails] = useState<any>();

  const body = queryString.parse(window.location.search);

  const fetchPaymentLink = async () => {
    try {
      const data: any = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/checkout/paymentlink/info?reference=${body?.reference}`
      );
      const paymentLink = data?.data?.data;
      setPaymentLinkDetails(paymentLink?.payment_link);
      setSubsidiaryDetails(paymentLink?.subsidiary);
    } catch (error: any) {
      const { message } = error?.response?.data;
    }
  };

  useEffect(() => {
    !!body?.encryptionKey === false && fetchPaymentLink();
  }, []);

  const { orderDetails } = useSelector((state) => state.orderDetailsReducer);
  const merchantLogo = orderDetails?.data?.subsidiary?.customization[1];
  const merchantName = orderDetails?.data?.subsidiary?.name;

  return (
    <div className={styles.container}>
      <div className={styles.companyLogo}>
        {!!merchantLogo?.value && (
          <img src={merchantLogo?.value} alt={merchantLogo?.key} />
        )}
        <div className={styles.nameBusiness}>
          {!!merchantName ? merchantName : subsidiaryDetails?.name}
        </div>
      </div>
      <div className={styles.tabs}>
        <div
          className={
            tab === "personal" ? styles.personalInfoActive : styles.personalInfo
          }
          onClick={() => {
            setTab("personal");
          }}
        >
          Personal Information
        </div>
        <div>
          <img src={TabArrow} alt="tab-arrow" />
        </div>
        <div
          className={tab === "payment" ? styles.paymentActive : styles.payment}
        // onClick={() => {
        //   setTab("payment");
        // }}
        >
          Payment{" "}
        </div>
      </div>

      <div>
        {tab === "personal" && (
          <PersonalInformation
            tab={tab}
            setTab={setTab}
            paymentLinkDetails={paymentLinkDetails}
            subsidiaryDetails={subsidiaryDetails}
          />
        )}
      </div>
      <div>{tab === "payment" && <PaymentDetails />}</div>
    </div>
  );
};

export default CheckoutHome;
