import React, { useEffect, useState } from "react";
import styles from "./Banks.module.scss";
import Select from "react-select";
import Modal from "react-modal";
import { BankDetails } from "../../constants/bankDetails";
import { apiClient } from "../../hooks/apiClient";
import {
  openLoader,
  closeLoader
} from "../../redux/actions/loader/loaderActions";
import { useDispatch } from "react-redux";
import {
  ToastSuccessStyles,
  ToastErrorStyles
} from "../../constants/toastStyles";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import payOrder from "../../api/payOrder";
import encryptForge from "../../utils/encryptForge";
import OutlineTextInput from "../input/OutlineTextInput";
import Button from "../button/Button";
import SuccessScreen from "../SuccessScreen/SuccessScreen";
import { customStyles } from "../../constants/modalStyles";

type BankProps = {
  reference: string;
  paymentReference: string;
};
const Banks = ({ reference, paymentReference }: BankProps) => {
  const dispatch = useDispatch();

  interface initTypes {
    accountNumber: string;
    accountName: string;
  }

  const initProps: initTypes = {
    accountNumber: "",
    accountName: ""
  };

  const [banks, setBanks] = useState<any>();
  const [inputs, setInputs] = React.useState(initProps);
  const [success, setSuccess] = React.useState(false);
  const [redirectUrl, setRedirectUrl] = React.useState("");

  const [modalIsOpen, setIsOpen] = React.useState(false);
  function closeModal() {
    setIsOpen(false);
  }

  const [selectedBank, setSelectedBank] = React.useState<any>();
  const [paymentResponse, setPaymentResponse] = React.useState<any>({
    code: "",
    message: ""
  });

  const [madePayment, setMadePayment] = React.useState<any>();

  const fetchBanks = async () => {
    dispatch(openLoader());
    try {
      const { data }: any = await apiClient.get(
        "checkout/banks/?paymentmethod=USSD"
      );
      setBanks(data?.data);
      dispatch(closeLoader());
    } catch (error: any) {
      dispatch(closeLoader());
      const message =
        error?.response?.data?.Message || error?.response?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message
        })
      );
    }
  };

  let bankOptions = banks?.map(function (permission: any) {
    return { value: permission?.bank_code, label: permission?.name };
  });

  const disabledFunction = () => {
    if (!inputs.accountName || !inputs.accountNumber) return true;
  };

  const errorResponse = (error: any) => {
    dispatch(closeLoader());
    const message =
      error?.response?.data?.message || error?.response?.data?.Message;
    dispatch(closeLoader());
    dispatch(
      openToastAndSetContent({
        toastStyles: ToastErrorStyles,
        toastContent: message
      })
    );
  };

  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;
    setInputs((prevState: initTypes) => {
      return {
        ...prevState,
        [inputName]: newValue
      };
    });
  };

  let redirectWin: any;

  const initiateOrder = async () => {
    const payload = {
      reference: reference,
      payment_option: "BA",
      bank_account: {
        bank_code: selectedBank?.value,
        account_number: inputs?.accountNumber,
        account_name: inputs?.accountName
      }
    };
    dispatch(openLoader());
    try {
      const encryptedData = encryptForge(JSON.stringify(payload));
      const { data }: any = await payOrder(encryptedData);
      getMadePayment()
      setRedirectUrl(data?.payment_detail?.redirect_url);
      dispatch(closeLoader());
      orderStatus();
      setIsOpen(true);
      // if (!!data?.payment_detail?.redirect_url) {
      //   redirectWin = window.open(
      //     data?.payment_detail?.redirect_url,
      //     "payment_redirect",
      //     "_blank"
      //   );
      // }
    } catch (error: any) {
      dispatch(closeLoader());
      errorResponse(error);
    }
  };

  const orderStatus = async () => {
    dispatch(openLoader());
    const statusInterval = setInterval(async () => {
      const payload = {
        reference: reference,
        payment_option: "BA",
        bank_account: {
          bank_code: selectedBank?.value,
          account_number: inputs?.accountNumber,
          account_name: inputs?.accountName
        }
      };
      const encryptedData = encryptForge(JSON.stringify(payload));
      try {
        const data: any = await apiClient.post("/checkout/order/status", {
          data: encryptedData
        });

        if (data?.data?.order_summary?.payment_response_code !== "01") {
          dispatch(closeLoader());
          setPaymentResponse({
            code: data?.data?.order_summary?.payment_response_code,
            message: data?.data?.order_summary?.payment_response_message
          });
          setSuccess(true);
          redirectWin.close();
          clearInterval(statusInterval);
        }
        const orderPayment = data?.data?.order_payments?.slice(-1)?.pop();
        if (
          data?.data?.order_summary?.payment_response_code === "01" &&
          orderPayment?.order_payment_response_code !== "01"
        ) {
          dispatch(closeLoader());
          setPaymentResponse({
            code: orderPayment?.order_payment_response_code,
            message: orderPayment?.order_payment_response_message
          });
          setSuccess(true);
          redirectWin.close();
          clearInterval(statusInterval);
        }
      } catch (error: any) {
        dispatch(closeLoader());
        errorResponse(error);
        clearInterval(statusInterval);
      }
    }, 2000);
  };

  const getMadePayment = async () => {
    // dispatch(openLoader());
    try {
      const data: any = await apiClient.get(`checkout/order/${reference}/made-payment`);
      setMadePayment(data)
      // dispatch(closeLoader());
      // orderStatus();
    } catch (error: any) {
      console.log(error)
      // dispatch(closeLoader());
      // errorResponse(error);
    }
  };

  useEffect(() => {
    fetchBanks();
  }, []);

  return (
    <>
      {success ? (
        <SuccessScreen response={paymentResponse} />
      ) : (
        <div className={styles.container}>
          <div className={styles.businessForm}>
            <form className={styles.formWidth}>
              <span className={styles.bankLabel}>
                Choose your bank to begin payment
              </span>
              <Select
                className={styles.select}
                defaultValue={selectedBank}
                onChange={(value) => {
                  setSelectedBank(value);
                }}
                options={bankOptions}
                isSearchable
                // onClick={initiateOrder}
                styles={{
                  // Fixes the overlapping problem of the component
                  menu: (provided) => ({ ...provided, zIndex: 9999 })
                }}
              />
              <OutlineTextInput
                handleChange={updateProps}
                inputName="accountNumber"
                inputLabel="Account Number"
                inputValue={inputs.accountNumber}
              />
              <OutlineTextInput
                handleChange={updateProps}
                inputName="accountName"
                inputLabel="Account Name"
                inputValue={inputs.accountName}
              />
              <div className={styles.verify}>
                You will be directed to your Bank’s verification page to
                complete this transaction.
              </div>
              <Button
                value="Continue"
                disabled={disabledFunction()}
                onClick={() => {
                  initiateOrder()
                }}
                className={
                  disabledFunction() ? styles.button : styles.buttonActive
                }
              />
            </form>
          </div>

        </div>
      )}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="test Modal"
        style={customStyles}
      >
        <div style={{
          padding: "20px"
        }}>
          <p style={{
            fontSize: "20px",
          }}>Click link below to complete payment</p>
          <a href={redirectUrl} onClick={closeModal} target="_blank" rel="noreferrer" style={{
            fontSize: "14px", paddingTop: "10px"
          }}>Redirect to payment page</a>
        </div>
      </Modal>
    </>
  );
};

export default Banks;
