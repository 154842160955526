import { combineReducers } from "redux";

import toast from "./toast/index";
import modal from "./modal/index";
import loader from "./loader/index";
import planReducer from "./plans/index";
import orderDetailsReducer from "./orderDetails/index";

const rootReducer = combineReducers({
  // customizer,
  // auth,
  // navbar,
  planReducer,
  toast,
  modal,
  loader,
  orderDetailsReducer,
});

export default rootReducer;
export type AppState = ReturnType<typeof rootReducer>;
