import * as React from "react";
import Styles from "./textfield.module.scss";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { makeStyles, Select } from "@material-ui/core";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import { useDispatch } from "react-redux";

interface TextInputProps {
  inputLabel: string;
  inputType?: string;
  inputShrink?: boolean;
  notRequired?: boolean;
  InputHelper?: string;
  inputSelect?: boolean;
  inputOption?: string[] | number[];
  multiline?: boolean;
  inputName: string;
  rows?: number;
  style?: React.CSSProperties;
  inputValue?: string | number;
  handleChange?: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  >;
}

const OutlineTextInput = ({
  inputLabel,
  inputType,
  notRequired,
  InputHelper,
  inputShrink,
  inputSelect = false,
  inputOption,
  multiline = false,
  inputName,
  inputValue,
  style,
  rows,
  handleChange
}: TextInputProps) => {
  const dispatch = useDispatch();
  interface State {
    amount: string;
    password: string;
    weight: string;
    weightRange: string;
    showPassword: boolean;
  }

  const useStyles = makeStyles({
    root: { color: "orange !important" },
    cssFocused: {
      fontFamily: "Kumbh Sans",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "12px !important",
      lineHeight: "16px",
      color: "#757575 !important",
      textTransform: "uppercase",
      borderRadius: "8px !important"
    },
    cssLabel: {
      color: "rgba(255, 255, 255, 0.81)"
    },
    cssOutlinedInput: {
      "&$cssFocused $notchedOutline": {
        borderColor: `#B6DBC1 !important`,
        borderWidth: "1px !important",
        borderRadius: "8px !important"
      }
    },
    notchedOutline: {
      borderRadius: "8px !important",
      borderColor: "#B6DBC1 !important"
    },
    helperText: {
      fontFamily: "Kumbh Sans !important",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "15px",
      color: "#757575 !important"
    }
  });
  const classes = useStyles();
  return (
    <TextField
      id="outlined-basic"
      label={inputLabel}
      onChange={handleChange}
      placeholder={inputLabel}
      select={inputSelect}
      style={style}
      required={notRequired ? false : true}
      classes={{ root: classes.root }}
      className={Styles.inputField}
      name={inputName}
      value={inputValue}
      variant="outlined"
      multiline={multiline}
      type={inputType ? inputType : ""}
      helperText={InputHelper ? InputHelper : ""}
      FormHelperTextProps={{ classes: { root: classes.helperText } }}
      rows={rows}
      InputLabelProps={{
        classes: {
          root: classes.cssLabel,
          focused: classes.cssFocused
        },
        shrink: inputShrink
      }}
      InputProps={{
        classes: {
          root: classes.cssOutlinedInput,
          focused: classes.cssFocused,
          notchedOutline: classes.notchedOutline
        }
      }}
    >
      {inputOption?.map((option, index) => (
        <option value={option} key={index}>
          {option}
        </option>
      ))}
    </TextField>
  );
};

export default OutlineTextInput;
