import { apiClient } from "../hooks/apiClient";

const orderStatus = async (body: any) => {
  const { data } = await apiClient.post("checkout/order/status", {
    data: body,
  });
  return data;
};

export default orderStatus;
