import React, { useEffect, useState } from "react";
import Select from "react-select";
import styles from "./Ussd.module.scss";
import { useDispatch } from "react-redux";
import { ReactComponent as ContentCopy } from "../../assets/images/content-copy.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { apiClient } from "../../hooks/apiClient";
import encryptForge from "../../utils/encryptForge";
import payOrder from "../../api/payOrder";
import orderStatus from "../../api/orderStatus";
import {
  closeLoader,
  openLoader
} from "../../redux/actions/loader/loaderActions";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import { ToastErrorStyles } from "../../constants/toastStyles";
import Button from "../button/Button";
import SuccessScreen from "../SuccessScreen/SuccessScreen";

const Ussd = ({ reference }: any) => {
  const dispatch = useDispatch();

  const [banks, setBanks] = useState<any>();
  const [selectedBank, setSelectedBank] = useState<any>();
  const [ussdCode, setUssdCode] = useState<any>();
  const [success, setSuccess] = useState(false);
  const [paymentResponse, setPaymentResponse] = React.useState<any>({
    code: "",
    message: ""
  });
  const [madePayment, setMadePayment] = React.useState<any>();
  let bankOptions = banks?.map(function (permission: any) {
    return { value: permission?.bank_code, label: permission?.name };
  });
  const [copied, setCopied] = useState(false);
  if (copied) {
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  }

  const errorResponse = (error: any) => {
    dispatch(closeLoader());
    const message =
      error?.response?.data?.message || error?.response?.data?.Message;
    dispatch(closeLoader());
    dispatch(
      openToastAndSetContent({
        toastStyles: ToastErrorStyles,
        toastContent: message
      })
    );
  };

  const fetchBanks = async () => {
    dispatch(openLoader());
    try {
      const { data }: any = await apiClient.get(
        "checkout/banks/?paymentmethod=USSD"
      );
      setBanks(data?.data);
      dispatch(closeLoader());
    } catch (error: any) {
      dispatch(closeLoader());
      const message =
        error?.response?.data?.Message || error?.response?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message
        })
      );
    }
  };

  const initiateOrder = async (value: { value: string; label: string }) => {
    const payload = {
      reference: reference,
      payment_option: "USSD",
      ussd: {
        bank_code: value?.value
      }
    };
    dispatch(openLoader());
    try {
      const encryptedData = encryptForge(JSON.stringify(payload));
      const { data }: any = await payOrder(encryptedData);

      setUssdCode(data?.payment_detail?.redirect_url);
      dispatch(closeLoader());
    } catch (error: any) {
      dispatch(closeLoader());
      errorResponse(error);
    }
  };

  const statusOrder = async () => {
    dispatch(openLoader());
    const statusInterval = setInterval(async () => {
      const payload = {
        reference: reference,
        payment_option: "USSD",
        ussd: {
          bank_code: selectedBank?.value
        }
      };
      const encryptedData = encryptForge(JSON.stringify(payload));
      try {
        const data: any = await orderStatus(encryptedData);

        if (data?.order_summary?.payment_response_code !== "01") {
          dispatch(closeLoader());
          setPaymentResponse({
            code: data?.order_summary?.payment_response_code,
            message: data?.order_summary?.payment_response_message
          });
          setSuccess(true);
          clearInterval(statusInterval);
        }

        const orderPayment = data?.order_payments?.slice(-1)?.pop();

        if (
          data?.order_summary?.payment_response_code === "01" &&
          orderPayment?.order_payment_response_code !== "01"
        ) {
          dispatch(closeLoader());
          setPaymentResponse({
            code: orderPayment?.order_payment_response_code,
            message: orderPayment?.order_payment_response_message
          });
          setSuccess(true);
          clearInterval(statusInterval);
        }
      } catch (error: any) {
        dispatch(closeLoader());
        errorResponse(error);
        clearInterval(statusInterval);
      }
    }, 2000);
  };



  const getMadePayment = async () => {
    dispatch(openLoader());
    try {
      const data: any = await apiClient.get(`checkout/order/${reference}/made-payment`);
      setMadePayment(data)
      dispatch(closeLoader());
    } catch (error: any) {
      dispatch(closeLoader());
      errorResponse(error);
    }
  };


  useEffect(() => {
    fetchBanks();
  }, []);

  return (
    <>
      {success ? (
        <SuccessScreen response={paymentResponse} />
      ) : (
        <div className={styles.container}>
          <div className={styles.businessForm}>
            <form className={styles.formWidth}>
              <span className={styles.bankLabel}>
                Choose your bank to begin payment
              </span>
              <Select
                className={styles.select}
                defaultValue={selectedBank}
                onChange={(value) => {
                  initiateOrder(value);
                  setSelectedBank(value);
                }}
                options={bankOptions}
                isSearchable
                // onClick={initiateOrder}
                styles={{
                  // Fixes the overlapping problem of the component
                  menu: (provided) => ({ ...provided, zIndex: 9999 })
                }}
              />
            </form>
            {!!ussdCode && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  marginTop: "10px"
                }}
              >
                <div className={styles.dial}>
                  Dial this code to complete payment with {selectedBank?.label}
                </div>
                <div className={styles.code}>{ussdCode}</div>
                <CopyToClipboard text={ussdCode} onCopy={() => setCopied(true)}>
                  <span className={styles.copy}>
                    {copied ? (
                      <div>Copied to clipboard!</div>
                    ) : (
                      <div>
                        <ContentCopy />
                        Copy
                      </div>
                    )}
                  </span>
                </CopyToClipboard>
                <Button
                  value="I’ve made payment"
                  onClick={() => {
                    getMadePayment()
                    statusOrder()
                  }}
                  className={styles.buttonActive}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Ussd;
