export const CLOSE_TOAST = "CLOSE_TOAST";
export const OPEN_AND_SET_TOAST_CONTENT = "OPEN_AND_SET_TOAST_CONTENT";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const OPEN_AND_SET_MODAL_CONTENT = "OPEN_AND_SET_MODAL_CONTENT";
export const CLOSE_LOADER = "CLOSE_LOADER";
export const OPEN_LOADER = "OPEN_LOADER";
export const SAVE_PLAN = "SAVE_PLAN";
export const SAVE_QUERY = "SAVE_QUERY";
export const SAVE_LOAN = "SAVE_LOAN";
export const SAVE_CARD = "SAVE_CARD";
export const SAVE_VERIFY = "SAVE_VERIFY";
export const SAVE_SELECTED_PLAN = "SAVE_SELECTED_PLAN";
export const SAVE_IDENTIFIER = "SAVE_IDENTIFIER";
export const SAVE_ORDER_DETAILS = "SAVE_ORDER_DETAILS";
