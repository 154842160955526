import React from "react";
import styles from "./success.module.scss";
import { ReactComponent as SuccessIcon } from "../../assets/images/check-circle.svg";
import { ReactComponent as ErrorIcon } from "../../assets/images/error.svg";

import Button from "../button/Button";

const SuccessScreen = ({ response }: any) => {
  const handleCloseModal = () => {
    sessionStorage.clear();
    window.parent.postMessage(
      {
        closeModal: true
      },
      "*"
    );
  };

  return (
    <div className={styles.successPortal}>
      {response?.code === "00" ? (
        <SuccessIcon />
      ) : response?.code === "04" ? (
        <ErrorIcon />
      ) : (
        <div>
          Your payment is being processed, we will send you a email once your
          payment is confirmed
        </div>
      )}
      <div className={styles.successPayment}>{response?.message}</div>
      <div className={styles.buttonActive} onClick={handleCloseModal}>
        Finish
      </div>
    </div>
  );
};

export default SuccessScreen;
