import React, { useEffect } from "react";
import Modal from "react-modal"
import OutlineTextInput from "../input/OutlineTextInput";
import { useDispatch } from "react-redux";
import styles from "./Debitcard.module.scss";
import Button from "../button/Button";
import { apiClient } from "../../hooks/apiClient";
import {
  openLoader,
  closeLoader
} from "../../redux/actions/loader/loaderActions";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import { ToastErrorStyles } from "../../constants/toastStyles";
import { usePaymentInputs } from "react-payment-inputs";
import encryptForge from "../../utils/encryptForge";
import SuccessScreen from "../SuccessScreen/SuccessScreen";
import { customStyles } from "../../constants/modalStyles";

type DebitCardProps = {
  reference: string;
};
const DebitCard = ({ reference }: DebitCardProps) => {
  interface initTypes {
    CardNumber: string;
    MonthAndYear: string;
    Cvv: string;
  }

  const initProps: initTypes = {
    CardNumber: "",
    MonthAndYear: "",
    Cvv: ""
  };

  const dispatch = useDispatch();
  const handleFormBlur = () => {
    // dispatch(updateIndividualForm({ ...inputs }));
  };

  const [inputs, setInputs] = React.useState(initProps);
  const [success, setSuccess] = React.useState(false);

  const [modalIsOpen, setIsOpen] = React.useState(false);
  function closeModal() {
    setIsOpen(false);
  }


  const { getExpiryDateProps } =
    usePaymentInputs();

  const [expiryDate, setExpiryDate] = React.useState<any>();
  const [redirectUrl, setRedirectUrl] = React.useState("");
  const [paymentResponse, setPaymentResponse] = React.useState<any>({
    code: "",
    message: ""
  });

  const [madePayment, setMadePayment] = React.useState<any>();


  const payload = {
    reference: reference,
    payment_option: "C",
    card: {
      card_number: inputs.CardNumber,
      cvv: inputs.Cvv,
      expiry_month: expiryDate?.substring(0, 2),
      expiry_year: expiryDate?.slice(-2)
    }
  };

  let redirectWin: any;
  const errorResponse = (error: any) => {
    dispatch(closeLoader());
    const message =
      error?.response?.data?.message || error?.response?.data?.Message;
    dispatch(
      openToastAndSetContent({
        toastStyles: ToastErrorStyles,
        toastContent: message
      })
    );
  };

  const initiateOrder = async () => {
    dispatch(openLoader());
    const encryptedData = encryptForge(JSON.stringify(payload));
    try {
      const data: any = await apiClient.post("/checkout/order/pay", {
        data: encryptedData
      });
      setRedirectUrl(data?.data?.data?.payment_detail?.redirect_url);
      getMadePayment()
      dispatch(closeLoader());
      orderStatus();
      setIsOpen(true);

      // if (!!data?.data?.data?.payment_detail?.redirect_url) {
      //   setIsOpen(true);
      //   redirectWin = window.open(
      //     data?.data?.data?.payment_detail?.redirect_url,
      //     "payment_redirect",
      //     "_blank"
      //   );
      // }

      // dispatch(closeLoader());
      // successResponse(encryptedData);
    } catch (error: any) {
      dispatch(closeLoader());
      errorResponse(error);
    }
  };

  const orderStatus = async () => {
    dispatch(openLoader());
    const statusInterval = setInterval(async () => {
      const encryptedData = encryptForge(JSON.stringify(payload));
      try {
        const data: any = await apiClient.post("/checkout/order/status", {
          data: encryptedData
        });
        if (data?.data?.order_summary?.payment_response_code !== "01") {
          dispatch(closeLoader());
          setPaymentResponse({
            code: data?.data?.order_summary?.payment_response_code,
            message: data?.data?.order_summary?.payment_response_message
          });
          setSuccess(true);
          redirectWin.close();
          clearInterval(statusInterval);
        }
        const orderPayment = data?.data?.order_payments?.slice(-1)?.pop();

        if (
          data?.data?.order_summary?.payment_response_code === "01" &&
          orderPayment?.order_payment_response_code !== "01"
        ) {
          dispatch(closeLoader());
          setPaymentResponse({
            code: orderPayment?.order_payment_response_code,
            message: orderPayment?.order_payment_response_message
          });
          setSuccess(true);
          redirectWin.close();
          clearInterval(statusInterval);
        }
      } catch (error: any) {
        dispatch(closeLoader());
        clearInterval(statusInterval);
        // const message =
        //   error?.response?.data?.message || error?.response?.data?.Message;
        // dispatch(
        //   openToastAndSetContent({
        //     toastStyles: ToastErrorStyles,
        //     toastContent: message
        //   })
        // );
      }
    }, 2000);
  };

  const getMadePayment = async () => {
    // dispatch(openLoader());
    try {
      const data: any = await apiClient.get(`checkout/order/${reference}/made-payment`);
      setMadePayment(data)
      // dispatch(closeLoader());
      // orderStatus();
    } catch (error: any) {
      // console.log(error)
      // dispatch(closeLoader());
      // errorResponse(error);
    }
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    initiateOrder();
  };



  const disabledFunction = () => {
    if (!inputs.CardNumber || !expiryDate || !inputs.Cvv) return true;
  };
  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;
    setInputs((prevState: initTypes) => {
      return {
        ...prevState,
        [inputName]: newValue
      };
    });
  };

  const handleChangeExpiryDate = (event: any) => {
    setExpiryDate(event.target.value);
  };
  return (
    <>
      {success ? (
        <SuccessScreen response={paymentResponse} />
      ) : (
        <div className={styles.businessForm}>
          <form
            onBlur={handleFormBlur}
            onSubmit={handleSubmit}
            className={styles.formWidth}
          >
            <OutlineTextInput
              handleChange={updateProps}
              inputName="CardNumber"
              inputLabel="Card Number"
              inputValue={inputs.CardNumber}
            />
            <div className={styles.cardDetails}>
              {/* <OutlineTextInput
                handleChange={updateProps}
                inputName="MonthAndYear"
                inputLabel="MM/YY"
                inputValue={inputs.MonthAndYear}
                style={{ width: "100%" }}
              /> */}
              <input
                {...getExpiryDateProps({ onChange: handleChangeExpiryDate })}
                value={expiryDate}
                name="expiryDate"
                className={styles.expiryDate}
              />
              <OutlineTextInput
                handleChange={updateProps}
                inputName="Cvv"
                inputLabel="CVV"
                inputValue={inputs.Cvv}
                style={{ width: "100%", marginLeft: "10px" }}
              />
            </div>

            <Button
              value="Pay"
              disabled={disabledFunction()}
              onClick={(e: any) => {
                handleSubmit(e)
              }}
              className={
                disabledFunction() ? styles.button : styles.buttonActive
              }
            />
          </form>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel="test Modal"
            style={customStyles}
          >
            <div style={{
              padding: "20px"
            }}>
              <p style={{
                fontSize: "20px",
              }}>Click link below to complete payment</p>
              <a href={redirectUrl} onClick={closeModal} target="_blank" rel="noreferrer" style={{
                fontSize: "14px", paddingTop: "10px"
              }}>Redirect to payment page</a>
            </div>
          </Modal>
        </div>
      )}
    </>
  );
};

export default DebitCard;
