import React, { Dispatch, SetStateAction, useState, useEffect } from "react";
import styles from "./Personalinformation.module.scss";
import OutlineTextInput from "../../components/input/OutlineTextInput";
import { useDispatch } from "react-redux";
import Button from "../../components/button/Button";
import { apiClient } from "../../hooks/apiClient";
import {
  openLoader,
  closeLoader
} from "../../redux/actions/loader/loaderActions";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import queryString from "query-string";
import { ToastErrorStyles } from "../../constants/toastStyles";
import { saveOrderDetails } from "../../redux/actions/orderDetails/orderDetailsActions";
import axios from "axios";
import encryptForge from "../../utils/encryptForge";
import BarLoader from "react-spinners/BarLoader";

type TabProps = {
  tab?: any;
  setTab: any;
  paymentLinkDetails: any;
  subsidiaryDetails: any;
};
const PersonalInformation = ({
  tab,
  setTab,
  paymentLinkDetails,
  subsidiaryDetails
}: TabProps) => {
  const [paymentKeys, setPaymentKeys] = useState<any>();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  interface initTypes {
    First_Name: any;
    Email_Address?: any;
    Last_Name?: any;
    Country_Id?: any;
    Mobile_Number?: any;
  }

  const body: any = queryString.parse(window.location.search);

  const queryPayload = {
    customer: {
      first_name: body.firstName,
      last_name: body.lastName,
      mobile: body.mobile,
      country: body.country,
      email: body.email
    },
    order: {
      amount: body?.amount,
      reference: body?.reference,
      description: body?.description,
      currency: body?.currency
    },
    Subsidiary: {
      Send_Customiza_tion: null
    }
  };
  if (!!body?.public_key && !!body?.encryptionKey) {
    sessionStorage.setItem("apiKey", body?.public_key);
    sessionStorage.setItem(
      "rsaPublicKey",
      (body?.encryptionKey).split(" ").join("+")
    );
  }

  const override = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "50px auto",
    borderColor: "red"
  };

  const queryEncryptUserData = async (key: string) => {
    setLoading(true);
    dispatch(openLoader());
    const encryptedData = encryptForge(JSON.stringify(queryPayload), key);
    try {
      const data: any = await apiClient.post("/checkout/order/create", {
        data: encryptedData
      });
      dispatch(closeLoader());
      dispatch(saveOrderDetails(data?.data));
      setTab("payment");
      setLoading(false);
    } catch (error: any) {
      dispatch(closeLoader());
      setLoading(false);
      const message =
        error?.response?.data?.Message || error?.response?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message
        })
      );
    }
  };

  const initProps: initTypes = {
    First_Name: "",
    Last_Name: "",
    Email_Address: "",
    Country_Id: "",
    Mobile_Number: "+234"
  };

  const [inputs, setInputs] = useState(initProps);

  const handleFormBlur = () => {
    // dispatch(updateIndividualForm({ ...inputs }));
  };

  const disabledFunction = () => {
    if (!inputs.First_Name || !inputs.Last_Name || !inputs.Email_Address)
      return true;
  };
  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;
    setInputs((prevState: initTypes) => {
      return {
        ...prevState,
        [inputName]: newValue
      };
    });
  };

  const payload = {
    customer: {
      first_name: inputs.First_Name,
      last_name: inputs.Last_Name,
      mobile: inputs.Mobile_Number,
      country: subsidiaryDetails?.country,
      email: inputs.Email_Address
    },
    order: {
      amount: !!paymentLinkDetails?.amount
        ? paymentLinkDetails?.amount
        : body?.amount,
      reference: paymentKeys?.payment_reference,
      description: paymentLinkDetails?.description,
      currency: paymentLinkDetails?.currency
    },
    Subsidiary: {
      Send_Customiza_tion: subsidiaryDetails?.customization
    }
  };

  const fetchPaymentKeys = async () => {
    setLoading(true);
    try {
      const data: any = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/checkout/paymentlink/get-keys`,
        {
          reference: body.reference
        }
      );
      setPaymentKeys(data?.data?.data);
      sessionStorage.setItem("apiKey", data?.data?.data?.public_key);
      sessionStorage.setItem(
        "rsaPublicKey",
        data?.data?.data?.public_encryption_key
      );
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      const { message } = error?.response?.data;
    }
  };

  const encryptUserData = async (payload: any, key: string) => {
    dispatch(openLoader());
    const encryptedData = encryptForge(JSON.stringify(payload), key);
    try {
      const data: any = await apiClient.post("/checkout/order/create", {
        data: encryptedData
      });
      dispatch(closeLoader());
      dispatch(saveOrderDetails(data?.data));
      setTab("payment");
    } catch (error: any) {
      dispatch(closeLoader());
      const message =
        error?.response?.data?.Message || error?.response?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message
        })
      );
    }
  };

  useEffect(() => {
    !!body?.encryptionKey
      ? queryEncryptUserData((body?.encryptionKey).split(" ").join("+"))
      : fetchPaymentKeys();
  }, []);

  if (loading && !!body?.encryptionKey) {
    return (
      <BarLoader
        color="#19943c"
        aria-label="Loading Spinner"
        data-testid="loader"
        cssOverride={override}
      />
    );
  }

  return (
    <div>
      <div className={styles.personalInfo}>Personal Information</div>
      <div className={styles.businessForm}>
        <div className={styles.amount}>
          Amount:
          <span>
            {paymentLinkDetails?.currency}
            {!!paymentLinkDetails?.amount
              ? paymentLinkDetails?.amount
              : body?.amount}
          </span>
        </div>
        <form
          onBlur={handleFormBlur}
          className={styles.formWidth}
          // onSubmit={handleSubmit}
        >
          <OutlineTextInput
            handleChange={updateProps}
            inputName="First_Name"
            inputLabel="First Name"
            inputValue={inputs.First_Name}
            style={{ padding: "0px" }}
          />
          <OutlineTextInput
            handleChange={updateProps}
            inputName="Last_Name"
            inputLabel="Last Name"
            inputValue={inputs.Last_Name}
          />
          <OutlineTextInput
            handleChange={updateProps}
            inputName="Email_Address"
            inputLabel="Email Address"
            inputValue={inputs.Email_Address}
          />
          <OutlineTextInput
            handleChange={updateProps}
            inputName="Country_Id"
            inputLabel="Country"
            inputValue={inputs.Country_Id}
          />
          <OutlineTextInput
            handleChange={updateProps}
            inputName="Mobile_Number"
            inputLabel="Phone Number"
            inputValue={inputs.Mobile_Number}
          />

          <Button
            value="Next"
            type="submit"
            onClick={() => {
              encryptUserData(payload, paymentKeys?.public_encryption_key);
            }}
            disabled={disabledFunction()}
            className={disabledFunction() ? styles.button : styles.buttonActive}
          />
        </form>
      </div>
    </div>
  );
};

export default PersonalInformation;

// http://localhost:3001/?first_name=Daniel&last_name=Johnson&mobile=+2348140710794&country=NG&email=arikawedaniel@gmail.com&currency=NGN&option=BT&amount=100&reference=ORD12DC89289033323&description=Food
