import { apiClient } from "../hooks/apiClient";

const payOrder = async (body: any) => {
  const { data } = await apiClient.post("checkout/order/pay", {
    data: body,
  });
  return data;
};

export default payOrder;
