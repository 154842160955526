import axios from "axios";
import queryString from "query-string";

const body = queryString.parse(window.location.search);
const apiKey = sessionStorage.getItem("apiKey");

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "api-key": apiKey || body?.apiKey
  }
});

apiClient.interceptors.request.use(
  async (config) => {
    const apiKey = sessionStorage.getItem("apiKey");
    config.headers["api-key"] = apiKey || body?.apiKey;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { apiClient };
// export const apiClient = axios.create({
//   baseURL: "https://api.payinvert.dev",
//   headers: {
//     "api-key": body?.apiKey,
//   },
// });
