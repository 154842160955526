import React, { Fragment, useState, useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import styles from "./Transfer.module.scss";
import { ReactComponent as ContentCopy } from "../../assets/images/content-copy.svg";
import Button from "../button/Button";
import { useSelector, useDispatch } from "react-redux";
import {
  closeLoader,
  openLoader
} from "../../redux/actions/loader/loaderActions";
import encryptForge from "../../utils/encryptForge";
import payOrder from "../../api/payOrder";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import { ToastErrorStyles } from "../../constants/toastStyles";
import { apiClient } from "../../hooks/apiClient";

const Transfer = ({ reference }: any) => {
  const dispatch = useDispatch();

  const [accountDetails, setAccountDetails] = useState<any>();
  const [madePayment, setMadePayment] = useState<any>();

  const [copied, setCopied] = useState(false);
  if (copied) {
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }


  const getMadePayment = async () => {
    dispatch(openLoader());
    try {
      const data: any = await apiClient.get(`checkout/order/${reference}/made-payment`);
      setMadePayment(data)
      dispatch(closeLoader());
      // orderStatus();
    } catch (error: any) {
      dispatch(closeLoader());
      errorResponse(error);
    }
  };

  const errorResponse = (error: any) => {
    dispatch(closeLoader());
    const message =
      error?.response?.data?.message || error?.response?.data?.Message;
    dispatch(closeLoader());
    dispatch(
      openToastAndSetContent({
        toastStyles: ToastErrorStyles,
        toastContent: message
      })
    );
  };

  const initiateOrder = async () => {
    const payload = {
      reference: reference,
      payment_option: "BT"
    };
    dispatch(openLoader());
    try {
      const encryptedData = encryptForge(JSON.stringify(payload));
      const { data }: any = await payOrder(encryptedData);

      setAccountDetails(data);
      dispatch(closeLoader());
      getMadePayment()
    } catch (error: any) {
      dispatch(closeLoader());
      errorResponse(error);
    }
  };


  useEffect(() => {
    initiateOrder();
  }, []);

  return (
    <Fragment>
      <div className={styles.transfer}>
        <div className={styles.funds}>
          Transfer NGN{" "}
          <span>{accountDetails?.order_payment?.total_amount}</span> to the
          account below
        </div>
        <div className={styles.bank}>
          {accountDetails?.bank_transfer_details?.bank_name}
        </div>
        <div className={styles.accountNumber}>
          {accountDetails?.bank_transfer_details?.bank_account}
        </div>
        <CopyToClipboard
          text={accountDetails?.bank_transfer_details?.bank_account}
          onCopy={() => setCopied(true)}
        >
          <span className={styles.copy}>
            {copied ? (
              <div>Copied to clipboard!</div>
            ) : (
              <div>
                <ContentCopy />
                Copy
              </div>
            )}
          </span>
        </CopyToClipboard>
      </div>
      {/* <Button value="I’ve made payment" className={styles.buttonActive} /> */}
    </Fragment>
  );
};

export default Transfer;
