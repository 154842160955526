const initialOrderState = {
  orderDetails: {},
};

export const orderDetailsReducer = (state = initialOrderState, action) => {
  switch (action.type) {
    case "SAVE_ORDER_DETAILS": {
      return {
        ...state,
        orderDetails: { ...action.orderDetails },
      };
    }
    default: {
      return state;
    }
  }
};

export default orderDetailsReducer;
